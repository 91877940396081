import React from 'react'
import Main from '../../layouts/Main'
import { Transition, FadeBottom } from '../../components/'
import styles from './Home.module.scss'
import { sections, food } from './Home.content'
import { Private } from '../../utils/authentication'

export default () => (
  <Main>
    <Transition>
      <Private>
        <UpperSection />

        <LowerSection />
      </Private>
    </Transition>
  </Main>
)

const UpperSection = () => (
  <section>
    <div className='container pt-5'>
      <div className='row'>
        {sections.map(([title, links]) => (
          <div
            key={title}
            className='col-sm'
          >
            <h3 className='hdln--4'>{title}</h3>

            <LinkList list={links} />
          </div>
        ))}
      </div>
    </div>
  </section>
)

const LowerSection = () => (
  <section>
    <div className='container pt-5 pb-5'>
      <div className='row'>
        <div className='col-sm-5'>
          <Food />
        </div>
      </div>
    </div>
  </section>
)

const LinkList = ({ list }) => (
  <ul>
    {list.map(link => (
      <li key={link.url}>
        <a href={link.url}>{link.title}</a>
      </li>
    ))}
  </ul>
)

const Food = () => (
  <FadeBottom>
    <div className={styles.food}>
      <h3>Yndisauki</h3>

      <LinkList list={food} />
    </div>
  </FadeBottom>
)
