import React from 'react'
import Home from '../page-templates/Home'
import Login from '../page-templates/Login'
import { isLoggedIn } from '../utils/authentication'

export default props => {
  if (!isLoggedIn()) {
    return <Login {...props} />
  }

  return <Home {...props} />
}
