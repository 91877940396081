import { parseLinkString as l } from '../../utils'

export const sections = [
  [
    'Basics',
    l`
      Asana https://app.asana.com/0/home/512740774746151
      Basecamp 3 https://3.basecamp.com/3597206/
      Basecamp 2 https://basecamp.com/2628380/
      Forecast https://forecastapp.com/90859/schedule/projects
      Harvest https://kosmosogkaos.harvestapp.com/time
      Invision Projects https://projects.invisionapp.com/d/main#/projects
      Slack https://kosmosogkaos.slack.com/
    `
  ],

  [
    'Design',
    l`
      Kosmos & Ferlar (Notion) https://www.notion.so/kosmosogkaos/Kosmos-ferlar-7a99d52a179345fabfd7941f389033ec
    `
  ],

  [
    'Dev',
    l`
      Handbók forritara https://sites.google.com/kosmosogkaos.is/handbok-forritara/home
      Github http://github.com/KosmosOgKaos/
      CircleCI https://circleci.com/dashboard
      Netlify https://app.netlify.com/
      Forritarabasecamp https://3.basecamp.com/3597206/projects/11772209
      Opnar púllur https://github.com/search?q=org%3AKosmosOgKaos+type%3Apr+is%3Aopen+sort%3Aupdated-asc
      Prismic https://prismic.io/dashboard
    `
  ],

  [
    'How',
    l`
      Tímaskráning https://docs.google.com/document/d/1sbq9lUwwJG6OlN33OqiZSGdIfm4YLJ9IiwmHIUekrLA/edit
      Ferlar verkefna #
    `
  ]
]

export const food = l`
  yndisauki.is https://yndisauki.is/
  Matseðill https://docs.google.com/spreadsheets/d/1IOfQEJ2Xwu8zwST6O-TBxStY1i1SFLklzNZrlFlf3Kw/edit?ouid=100377737319121470594&usp=sheets_home&ths=true
`
